<template>
  <div class="">
    <p class="display-1 mt-2 mb-5 text-uppercase">
      {{ $t('title-request-a-quote') }}
    </p>
    <!-- <VesselScheduleListItem :item="vesselSchedule" /> -->
    <VesselScheduleSearchResultListItemV2
      v-if="!isLoading"
      :schedules="vesselScheduleDetail"
      hide-booking
      class="mb-2"
    />
    <v-row
      no-gutters
      class="mt-4"
    >
      <v-col
        :cols="$vuetify.breakpoint.mobile ? 12 : 6"
        class="pr-4"
      >
        <QuotationFormSelectContact style="height: 100%" />
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
        <QuotationFormSenderNote style="height: 100%" />
      </v-col>
    </v-row>
    <QuotationFormTerm class="mt-4" />
    <QuotationFormCommodity class="mt-4" />
    <v-row no-gutters>
      <p
        class="text-secondary pa-2"
        style="font-size: 12px; font-style: italic"
      >
        {{ $t('text-quotation-send-warn') }}
      </p>
    </v-row>

    <v-row
      no-gutters
      class="mt-1 mb-10"
    >
      <v-spacer />
      <v-btn
        :disabled="!isFormValid || isLoading"
        :loading="isLoading"
        color="primary"
        large
        @click="onSubmit"
      >
        <v-icon left>mdi-send</v-icon>
        Gửi yêu cầu báo giá
      </v-btn>
      <v-spacer />
    </v-row>
  </div>
</template>

<script>
import VesselScheduleSearchResultListItemV2 from '@/components/shared/local/vsl/VesselScheduleSearch/VesselScheduleSearchResultListItemV2.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import VesselScheduleListItem from '../VesselSchedule/VesselScheduleListItem.vue';
import QuotationFormBasicContact from './QuotationFormBasicContact.vue';
import QuotationFormCommodity from './QuotationFormCommodity';
import QuotationFormSelectContact from './QuotationFormSelectContact.vue';
import QuotationFormSenderNote from './QuotationFormSenderNote.vue';
import QuotationFormTerm from './QuotationFormTerm.vue';
export default {
  components: {
    VesselScheduleListItem,
    QuotationFormBasicContact,
    QuotationFormSenderNote,
    QuotationFormTerm,
    QuotationFormCommodity,
    QuotationFormSelectContact,
    VesselScheduleSearchResultListItemV2,
  },
  data: () => ({
    vesselScheduleDetail: {
      type: null,
      path: null,
      voyageCodes: [],
      pathSchedules: [],
    },
    isLoading: false,
    isFormValid: false,
  }),
  computed: {
    ...mapState('vsl/vesselSchedule', ['vesselSchedule']),
    ...mapState('vsl/quotation', ['requestQuotation']),
  },
  async created() {
    try {
      this.isLoading = true;
      const { schedules_codes, path } = this.$route.query;
      const schedulesCodes = schedules_codes.split(',');
      this.vesselScheduleDetail.path = path.split(',');
      this.SET_REQUEST_QUOTATION({ vesselScheduleCodes: schedulesCodes });

      for (const [index, code] of schedulesCodes.entries()) {
        await this.fetchVesselSchedule({ code });
        this.vesselScheduleDetail.voyageCodes.push(
          this.vesselSchedule.voyageCode
        );
        this.vesselScheduleDetail.pathSchedules.push(this.vesselSchedule);
        if (index === 0) {
          this.SET_REQUEST_QUOTATION({ polCode: this.vesselSchedule.polCode });
        }
        if (index === schedulesCodes.length - 1) {
          this.SET_REQUEST_QUOTATION({ podCode: this.vesselSchedule.podCode });
        }
      }
    } finally {
      this.isLoading = false;
    }
  },
  destroyed() {
    this.RESET();
  },
  watch: {
    requestQuotation: {
      handler(value) {
        const {
          vesselScheduleCodes,
          polTermCode,
          podTermCode,
          senderName,
          senderEmail,
          senderPhone,
          senderAddress,
          commodities,
        } = value;

        if (
          vesselScheduleCodes.length > 0 &&
          polTermCode &&
          podTermCode &&
          senderName.length > 0 &&
          senderEmail.length > 0 &&
          commodities.length > 0
        ) {
          this.isFormValid = true;
        } else {
          this.isFormValid = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('vsl/quotation', ['RESET', 'SET_REQUEST_QUOTATION']),
    ...mapActions('vsl/quotation', ['createRequestQuotation']),
    ...mapActions('vsl/vesselSchedule', ['fetchVesselSchedule']),
    async onSubmit() {
      try {
        if (this.isFormValid) {
          this.isLoading = true;
          await this.createRequestQuotation();
          this.$message.success(this.$t('text-quotation-send-success'));
          this.$router.push('/app/shipping/my-quotation');
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style></style>
