<template>
  <v-card rounded="xl">
    <v-card-text>
      <v-row
        no-gutters
        class="text-left text-h6 font-weight-regular"
      >
        <span class="d-flex align-center">
          <v-icon
            color="primary"
            large
            left
            >mdi-information-outline</v-icon
          >
          <span class="primary--text text-uppercase">{{
            $t('title-contact-infor')
          }}</span>
        </span>
        <v-spacer />
        <v-btn
          color="primary"
          text
          :ripple="false"
          @click="dialog.picker = true"
        >
          <v-icon left>mdi-sync</v-icon>
          {{ $t('button-change') }}
        </v-btn>
      </v-row>
      <div class="text-left mt-2">
        <div
          v-if="!isLoading"
          class="mb-2"
        >
          <div>
            <span class="font-weight-bold">{{ selectedItem.name }}</span>
            <span class="mr-2"> | {{ selectedItem.phone }}</span>
          </div>
          <div>
            {{ selectedItem.emails.join(', ') }}
          </div>
          <div>
            {{ selectedItem.address }}
          </div>
        </div>
        <template v-if="isLoading">
          <v-card
            class="mb-2"
            flat
          >
            <v-skeleton-loader
              type="text@4"
              :boilerplate="false"
              class="mb-2"
            />
          </v-card>
        </template>
      </div>
    </v-card-text>
    <v-dialog
      v-model="dialog.picker"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <QuotationFormSelectContactList
        v-if="dialog.picker"
        style="height: 80vh"
        @close-dialog="dialog.picker = false"
        @change="onChangeContact"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import jwt_decode from 'jwt-decode';
import QuotationFormSelectContactList from './QuotationFormSelectContactList.vue';

export default {
  components: { QuotationFormSelectContactList },
  data: () => ({
    isLoading: false,
    dialog: {
      picker: false,
    },
    selectedItem: null,
  }),
  computed: {
    ...mapState('userContact', ['contactList']),
    ...mapState('vsl/quotation', ['requestQuotation']),
    senderName: {
      get() {
        return this.requestQuotation.senderName;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({ senderName: value });
      },
    },
    senderAddress: {
      get() {
        return this.requestQuotation.senderAddress;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({ senderAddress: value });
      },
    },
    senderEmail: {
      get() {
        return this.requestQuotation.senderEmail;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({ senderEmail: value });
      },
    },
    senderPhone: {
      get() {
        return this.requestQuotation.senderPhone;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({ senderPhone: value });
      },
    },

    receiveName: {
      get() {
        return this.requestQuotation.receiveName;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({ receiveName: value });
      },
    },
  },
  watch: {},
  async created() {
    try {
      this.isLoading = true;
      await this.fetchContactList();
      this.selectedItem = this.contactList.find((contact) => contact.isDefault);
      this.senderName = this.selectedItem.name;
      this.senderPhone = this.selectedItem.phone;
      this.senderEmail = this.selectedItem.emails.toString();
      this.senderAddress = this.selectedItem.address;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapMutations('vsl/quotation', ['SET_REQUEST_QUOTATION']),
    ...mapActions('userContact', ['fetchContactList']),
    onChangeContact(event) {
      this.selectedItem = event;
      this.senderName = this.selectedItem.name;
      this.senderPhone = this.selectedItem.phone;
      this.senderEmail = this.selectedItem.emails.toString();
      this.senderAddress = this.selectedItem.address;
    },
  },
};
</script>

<style></style>
