<template>
  <div>
    <div>
      <span class="font-weight-bold">{{ item.name }}</span>
      <span class="mr-2"> | {{ item.phone }}</span>
      <v-chip
        color="error"
        x-small
        v-if="item.isDefault"
        >Mặc định</v-chip
      >
    </div>
    <div>
      {{ item.emails.join(', ') }}
    </div>
    <div>
      {{ item.address }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    isLoading: false,
  }),
  methods: {},
};
</script>

<style></style>
