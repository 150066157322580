<template>
  <v-card
    class="mt-2"
    rounded="xl"
    :ripple="false"
    :to="to"
  >
    <v-card-text style="font-size: 12px">
      <v-row
        no-gutters
        class="align-center"
      >
        <v-col
          cols="2"
          class="font-weight-bold primary--text text-truncate"
          style="font-size: 11px"
        >
          {{ item.vesselName }}
        </v-col>
        <v-col
          cols="1"
          class="font-weight-bold primary--text text-truncate"
          style="font-size: 11px"
        >
          {{ item.voyageCode }}
        </v-col>
        <v-col
          cols="2"
          class="text-center text-truncate"
          style="font-size: 11px; font-weight: 600"
        >
          <div class="text-center text-truncate">
            {{ item.polTerminalName }}
          </div>
          <div class="text-center text-truncate">
            {{ item.polName }}
          </div>
        </v-col>
        <v-col
          cols="3"
          class="text-center text-truncate"
          style="font-size: 11px; font-weight: 600"
        >
          <div class="text-truncate">
            {{ item.podTerminalName }}
          </div>
          <div class="text-truncate">
            {{ item.podName }}
          </div>
        </v-col>

        <v-col
          cols="2"
          class="text-center text-truncate"
        >
          <div>
            <span class="font-weight-bold">{{ formatDate(item.etd) }}</span>
            -
            <span class="font-weight-bold">{{ formatDate(item.eta) }}</span>
          </div>
          <div></div>
        </v-col>
        <v-col
          cols="2"
          class="text-center text-truncate"
        >
          <v-chip
            v-if="item.vesselSlot"
            color="success"
            outlined
          >
            {{ formatVesselSlot(item.vesselSlot) }}
          </v-chip>
          <v-chip
            v-if="!item.vesselSlot"
            color="error"
            outlined
          >
            {{ formatVesselSlot(item.vesselSlot) }}
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    to: {
      type: Object,
    },
  },
  components: {},
  data: () => ({}),
  computed: {},
  async created() {},
  methods: {
    formatDate(value) {
      return dayjs(value).format('DD.MM.YYYY');
    },
    formatVesselSlot(value) {
      if (value === true) {
        return this.$t('text-slot-available');
      }
      if (value === false) {
        return this.$t('text-slot-not-available');
      }
    },
  },
};
</script>

<style></style>
