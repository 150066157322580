<template>
  <v-card rounded="xl">
    <v-card-text>
      <p class="text-left text-h6 font-weight-regular">
        <span class="d-flex align-center">
          <v-icon
            color="primary"
            large
            left
            >mdi-file-outline</v-icon
          >
          <span class="primary--text">{{ $t('title-note') }}</span>
        </span>
      </p>
      <v-textarea
        v-model="senderNote"
        :label="$t('label-note')"
        outlined
        dense
        rows="3"
        persistent-placeholder
        hide-details
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
export default {
  data: () => ({}),
  computed: {
    ...mapState('vsl/quotation', ['requestQuotation']),
    senderNote: {
      get() {
        return this.requestQuotation.senderNote;
      },
      set(value) {
        this.SET_REQUEST_QUOTATION({ senderNote: value });
      },
    },
  },
  methods: {
    ...mapMutations('vsl/quotation', ['SET_REQUEST_QUOTATION']),
  },
};
</script>

<style></style>
