<template>
  <v-card rounded="xl">
    <v-card-text>
      <v-row no-gutters>
        <p class="text-left text-h6 font-weight-regular">
          <span class="d-flex align-center">
            <v-icon
              color="primary"
              large
              left
              >mdi-package-variant</v-icon
            >
            <span class="primary--text text-uppercase">{{
              $t('title-commodity-info')
            }}</span>
          </span>
        </p>
        <v-spacer />
        <v-btn
          color="primary"
          @click="dialog.quotationFormCommodityCreate = true"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t('button-add-commodity') }}
        </v-btn>
      </v-row>
      <QuotationFormCommodityList />
    </v-card-text>
    <v-dialog
      v-model="dialog.quotationFormCommodityCreate"
      max-width="750px"
      :fullscreen="$vuetify.breakpoint.mobile"
      style="max-width: 100vw"
    >
      <QuotationFormCommodityCreate
        v-if="dialog.quotationFormCommodityCreate"
        @close-dialog="dialog.quotationFormCommodityCreate = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import QuotationFormCommodityCreate from './QuotationFormCommodityCreate.vue';
import QuotationFormCommodityList from './QuotationFormCommodityList.vue';

export default {
  components: {
    QuotationFormCommodityList,
    QuotationFormCommodityCreate,
  },
  data: () => ({
    dialog: {
      quotationFormCommodityCreate: false,
    },
  }),
  computed: {
    ...mapState('vsl/quotation', ['requestQuotation']),
  },
  async created() {},
  methods: {
    ...mapMutations('vsl/quotation', ['SET_REQUEST_QUOTATION']),
  },
};
</script>

<style></style>
