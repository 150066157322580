var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { rounded: "xl" } },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            {
              staticClass: "text-left text-h6 font-weight-regular",
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "span",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "primary", large: "", left: "" } },
                    [_vm._v("mdi-information-outline")]
                  ),
                  _c("span", { staticClass: "primary--text text-uppercase" }, [
                    _vm._v(_vm._s(_vm.$t("title-contact-infor"))),
                  ]),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "", ripple: false },
                  on: {
                    click: function ($event) {
                      _vm.dialog.picker = true
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-sync")]),
                  _vm._v(" " + _vm._s(_vm.$t("button-change")) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-left mt-2" },
            [
              !_vm.isLoading
                ? _c("div", { staticClass: "mb-2" }, [
                    _c("div", [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.selectedItem.name)),
                      ]),
                      _c("span", { staticClass: "mr-2" }, [
                        _vm._v(" | " + _vm._s(_vm.selectedItem.phone)),
                      ]),
                    ]),
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.selectedItem.emails.join(", ")) + " "
                      ),
                    ]),
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.selectedItem.address) + " "),
                    ]),
                  ])
                : _vm._e(),
              _vm.isLoading
                ? [
                    _c(
                      "v-card",
                      { staticClass: "mb-2", attrs: { flat: "" } },
                      [
                        _c("v-skeleton-loader", {
                          staticClass: "mb-2",
                          attrs: { type: "text@4", boilerplate: false },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            scrollable: "",
            "max-width": "500px",
            transition: "dialog-transition",
          },
          model: {
            value: _vm.dialog.picker,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "picker", $$v)
            },
            expression: "dialog.picker",
          },
        },
        [
          _vm.dialog.picker
            ? _c("QuotationFormSelectContactList", {
                staticStyle: { height: "80vh" },
                on: {
                  "close-dialog": function ($event) {
                    _vm.dialog.picker = false
                  },
                  change: _vm.onChangeContact,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }