<template>
  <v-card rounded>
    <v-card-title>
      <v-row no-gutters>
        <div>{{ $t('title-commodity-add') }}</div>
        <v-spacer />
        <div
          :style="
            $vuetify.breakpoint.mobile ? 'width: 100%; text-align: right' : ''
          "
        >
          <v-btn
            :disabled="isLoading"
            text
            class="mr-2"
            @click="onCloseDialog"
            >{{ $t('button-close') }}</v-btn
          >
          <v-btn
            :disabled="isLoading"
            :loading="isLoading"
            color="success"
            @click="onCreateCommodity"
            >{{ $t('button-add') }}
          </v-btn>
        </div>
      </v-row>
    </v-card-title>

    <v-card-text class="pb-6 pt-2">
      <v-form>
        <v-row no-gutters>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
            <CommodityPicker
              class="pr-1"
              outlined
              dense
              auto-select-first
              :label="$t('label-commodity-picker')"
              :required="emptyLaden === 'LADEN'"
              :clearable="emptyLaden === 'EMPTY'"
              :value="commodityCode"
              @change="onSelectCommodity"
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
            <ContainerPicker
              class="pr-1"
              required
              outlined
              dense
              auto-select-first
              :label="$t('label-container-picker')"
              :value="containerCode"
              @change="onSelectContainer"
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 3">
            <ContainerEmptyLadenPicker
              class="pr-1"
              required
              auto-select-first
              :label="$t('label-container-empty-laden-picker')"
              :value="emptyLaden"
              @change="onSelectContainerEmptyLaden"
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
            <ContainerCocSocPicker
              required
              auto-select-first
              :label="$t('label-coc-soc-picker')"
              :value="cocSoc"
              @change="onSelectContainerCocSoc"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
            <CommodityPrepaidPicker
              class="pr-1"
              required
              auto-select-first
              :label="$t('label-prepaid')"
              :value="prepaid"
              @change="onSelectCommodityPrepaid"
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
            <BaseTextField
              class="pr-1"
              v-model="containerNewQuantity"
              :label="$t('label-container-new-quantity')"
              required
              outlined
              dense
              type="number"
              min="0"
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
            <BaseTextField
              class="pr-1"
              v-model="containerMixedQuantity"
              :label="$t('label-container-mixed-quantity')"
              required
              outlined
              dense
              type="number"
              min="0"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
            <BaseTextField
              class="pr-1"
              v-model="weight"
              :label="$t('label-weight')"
              persistent-placeholder
              outlined
              dense
              type="text"
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
            <BaseTextField
              class="pl-1"
              v-model="size"
              :label="$t('label-size')"
              persistent-placeholder
              outlined
              dense
              type="text"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import CommodityPicker from '@/components/shared/local/vsl/CommodityPicker.vue';
import ContainerPicker from '@/components/shared/local/vsl/ContainerPicker.vue';
import ContainerCocSocPicker from '@/components/shared/local/vsl/ContainerCocSocPicker.vue';
import ContainerEmptyLadenPicker from '@/components/shared/local/vsl/ContainerEmptyLadenPicker.vue';
import CommodityPrepaidPicker from '@/components/shared/local/vsl/CommodityPrepaidPicker.vue';
import BaseTextField from '@/components/shared/local/base/BaseTextField.vue';

export default {
  components: {
    CommodityPicker,
    ContainerPicker,
    ContainerCocSocPicker,
    ContainerEmptyLadenPicker,
    CommodityPrepaidPicker,
    BaseTextField,
  },
  data: () => ({
    isLoading: false,
    name: '',
    commodityCode: null,
    containerCode: null,
    cocSoc: null,
    emptyLaden: null,
    prepaid: true,
    containerNewQuantity: 0,
    containerMixedQuantity: 0,
    quantity: 0,
    size: '',
    weight: '',
  }),
  computed: {
    ...mapState('vsl/quotation', ['requestQuotation']),
  },
  async created() {},
  methods: {
    ...mapActions('vsl/quotation', ['calculateCommodityPrices']),
    onCloseDialog() {
      this.$emit('close-dialog');
    },
    async onCreateCommodity() {
      try {
        if (!this.commodityCode && this.emptyLaden === 'LADEN') {
          return this.$message.error('Vui lòng chọn hàng hóa');
        }
        if (!this.containerCode) {
          return this.$message.error('Vui lòng chọn loại container');
        }
        if (!this.cocSoc) {
          return this.$message.error('Vui lòng chọn COC/SOC');
        }
        if (!this.emptyLaden) {
          return this.$message.error('Vui lòng chọn container rỗng/hàng');
        }
        if (this.containerMixedQuantity + this.containerNewQuantity < 1) {
          return this.$message.error('Số lượng container phải lớn hơn 0');
        }
        this.isLoading = true;
        const newItem = {
          name: this.name,
          commodityCode: this.commodityCode,
          containerCode: this.containerCode,
          cocSoc: this.cocSoc,
          emptyLaden: this.emptyLaden,
          prepaid: this.prepaid,
          containerMixedQuantity: this.containerMixedQuantity,
          containerNewQuantity: this.containerNewQuantity,
          quantity: this.quantity,
          size: this.size,
          weight: this.weight,
        };
        await this.calculateCommodityPrices([
          ...this.requestQuotation.commodities,
          newItem,
        ]);
        this.$emit('close-dialog');
      } finally {
        this.isLoading = false;
      }
    },
    onSelectCommodity(item) {
      if (item) {
        this.commodityCode = item.code;
        this.name = item.name;
      } else {
        this.commodityCode = null;
        this.name = null;
      }
    },
    onSelectContainer(item) {
      this.containerCode = item?.code;
    },
    onSelectContainerCocSoc(value) {
      this.cocSoc = value;
    },
    onSelectContainerEmptyLaden(value) {
      this.emptyLaden = value;
    },
    onSelectCommodityPrepaid(value) {
      this.prepaid = value;
    },
  },
};
</script>

<style></style>
