<template>
  <div class="pa-0">
    <div style="min-height: 350px">
      <v-card
        class="mt-3"
        elevation="0"
        rounded="xl"
        v-if="!$vuetify.breakpoint.mobile"
      >
        <v-card-text style="font-size: 11px">
          <v-row no-gutters>
            <v-col
              cols="5"
              class="font-weight-bold text-uppercase"
            >
              {{ $t('col-commodity') }}
            </v-col>
            <v-col
              cols="5"
              class="font-weight-bold text-uppercase"
            >
              {{ $t('col-price') }}
            </v-col>
            <v-col
              cols="2"
              class="text-center font-weight-bold text-uppercase"
            >
              {{ $t('col-action') }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-for="item in requestQuotation.commodities"
        :key="item.id"
        :item="item"
        class="mt-2"
        color="background"
        elevation="0"
        rounded="xl"
      >
        <v-card-text>
          <v-row
            no-gutters
            class="align-center"
          >
            <v-col
              :cols="$vuetify.breakpoint.mobile ? 12 : 5"
              class="text-truncate"
            >
              <ul class="pa-0">
                <li v-if="item.commodityCode">
                  <b>{{ $t('row-commodity') }}</b>
                  <span>: {{ item.name }}</span>
                </li>
                <li>
                  <b>{{ $t('row-container') }}</b
                  ><span>: {{ item.containerCode }}</span>
                </li>
                <li>
                  <b>COC/SOC</b><span>: {{ item.cocSoc }}</span>
                </li>
                <li>
                  <b>{{ $t('row-empty-laden') }}</b>
                  <span>: {{ formatEmptyLaden(item.emptyLaden) }}</span>
                </li>
                <li v-if="item.containerMixedQuantity">
                  <b>{{ $t('row-container-mixed-quantity') }}</b>
                  <span>: {{ item.containerMixedQuantity }} container</span>
                </li>
                <li v-if="item.containerNewQuantity">
                  <b>{{ $t('row-container-new-quantity') }}</b>
                  <span>: {{ item.containerNewQuantity }} container</span>
                </li>
                <li>
                  <b>{{ $t('row-payment') }}</b
                  ><span>: {{ formatPrepaid(item.prepaid) }}</span>
                </li>
                <li v-if="item.weight && item.weight.length > 0">
                  <b>{{ $t('row-weight') }}</b
                  ><span>: {{ item.weight }}</span>
                </li>
                <li v-if="item.size && item.size.length > 0">
                  <b>{{ $t('row-size') }}</b
                  ><span>: {{ item.size }}</span>
                </li>
              </ul>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mobile ? 12 : 5"
              class="text-truncate"
            >
              <ul class="pa-0">
                <li>
                  <b>{{ $t('row-unit-price') }}</b>
                  <span
                    >:
                    {{ formatCurrency(item.price) + ' ' + item.currencyCode }} /
                    container</span
                  >
                </li>
                <li>
                  <b>{{ $t('row-quantity') }}</b
                  ><span>: {{ item.quantity }} container</span>
                </li>
                <li>
                  <b>{{ $t('row-total-price') }}</b>
                  <span
                    >:
                    {{
                      formatCurrency(item.total) + ' ' + item.currencyCode
                    }}</span
                  >
                </li>
              </ul>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mobile ? 12 : 2"
              class="text-center"
              :class="{
                'd-flex justify-end': $vuetify.breakpoint.mobile,
              }"
            >
              <v-icon
                color="orange darken-4"
                class="mr-2"
                @click="onUpdateItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                color="error"
                @click="onDeleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <NoData
        v-if="requestQuotation.commodities.length === 0"
        rounded="lg"
        height="348px"
        :text="$t('text-no-data')"
      />
    </div>
    <v-dialog
      v-model="dialog.quotationFormCommodityDelete"
      max-width="550px"
    >
      <QuotationFormCommodityDelete
        v-if="dialog.quotationFormCommodityDelete"
        :item="selectedItem"
        @close-dialog="dialog.quotationFormCommodityDelete = false"
      />
    </v-dialog>
    <v-dialog
      v-model="dialog.quotationFormCommodityUpdate"
      max-width="850px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <QuotationFormCommodityUpdate
        v-if="dialog.quotationFormCommodityUpdate"
        :item="selectedItem"
        @close-dialog="dialog.quotationFormCommodityUpdate = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import numeral from 'numeral';
import { mapActions, mapMutations, mapState } from 'vuex';
import QuotationFormCommodityDelete from './QuotationFormCommodityDelete.vue';
import QuotationFormCommodityUpdate from './QuotationFormCommodityUpdate.vue';
import NoData from '@/components/shared/local/base/NoData.vue';

export default {
  components: {
    QuotationFormCommodityDelete,
    QuotationFormCommodityUpdate,
    NoData,
  },
  data: () => ({
    dialog: {
      quotationFormCommodityDelete: false,
      quotationFormCommodityUpdate: false,
    },
    selectedItem: null,
  }),
  computed: {
    ...mapState('vsl/quotation', ['requestQuotation']),
  },
  async created() {},
  methods: {
    formatDate(value) {
      return dayjs(value).format('DD.MM.YYYY');
    },
    formatCurrency(value) {
      return numeral(value).format(0, 0);
    },
    formatEmptyLaden(value) {
      if (value === 'LADEN') {
        return this.$t('laden');
      }
      if (value === 'EMPTY') {
        return this.$t('empty');
      }
      return value;
    },
    formatPrepaid(value) {
      if (value === true) {
        return this.$t('prepaid');
      }
      if (value === false) {
        return this.$t('postpaid');
      }
      return value;
    },
    onUpdateItem(item) {
      this.selectedItem = item;
      this.dialog.quotationFormCommodityUpdate = true;
    },
    onDeleteItem(item) {
      this.selectedItem = item;
      this.dialog.quotationFormCommodityDelete = true;
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  display: table;
  width: 100%;
  table-layout: fixed;
}

li {
  display: table-row;
}

b {
  display: table-cell;
  width: 150px;
}

span {
  display: table-cell;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
